.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Polaris-TopBar__Logo {
  width: 150px !important;
}

.Polaris-EmptyState__Image {
  max-width: 400px;
  width: 100% !important;
}

.comment .Polaris-Icon {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 4px;
}

.roadmap {
  border-left: 3px solid #b6b6b6;
  padding-top: 24px;
  padding-bottom: 24px;
  margin-left: 12px;
  margin-top: 48px;
}

.plan {
  padding: 0px 24px;
  position: relative;
  margin-bottom: 48px;
}

.plan .bullet {
  background-color: #3b7b6e;
  border-radius: 50px;
  height: 50px;
  width: 50px;
  font-size: 24px;
  font-weight: bold;
  font-family: var(--font-primary);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  position: absolute;
  left: -25px;
}

.nav-links {
  display: flex;
  align-items: center;
}

.plan-content {
  padding-left: 24px;
}

.plan-content p {
  margin: 16px 0px;
}

/* MARKETING PAGE STYLES */

:root {
  --font-primary: 'Poppins', sans-serif;
}

.navbar {
  padding: 16px 8px;
  border-bottom: 1px solid #dcdcdc;
}

.navbar-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flex-row {
  display: flex;
  align-items: center;
}

.navbar-content button {
  margin-left: 12px;
}

.navbar-content a {
  font-family: var(--font-primary);
  color: #000;
  text-decoration: none;
  font-size: 16px;
  margin-left: 12px;
  margin-right: 12px;
}

.navbar img {
  height: 32px;
  width: auto;
}

.section {
  padding: 24px 8px;
}

.container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.hero-bg {
  background-size: cover;
  background-position: center;
  background-image: linear-gradient(
      rgba(246, 246, 247, 0.8),
      rgba(246, 246, 247, 0.5)
    ),
    url('https://storage.googleapis.com/happy-team-de21b.appspot.com/hero-bg-nw.png');
}

.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 90px 0px;
}

.hero h1 {
  font-size: 52px;
  font-weight: 600;
  font-family: var(--font-primary);
  line-height: 1.25;
  text-align: center;
  max-width: 800px;
  letter-spacing: 0px;
  margin-bottom: 16px;
}

.hero h2 {
  font-size: 22px;
  font-weight: 500;
  font-family: var(--font-primary);
  line-height: 1.5;
  text-align: center;
  max-width: 600px;
  letter-spacing: 0px;
  color: #2e2e2f;
  margin-bottom: 18px;
}

.hero-illo-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 48px;
}

.hero-illo-container .one {
  max-width: 800px;
  width: 100%;
  height: auto;
  border-radius: 18px;
  box-shadow: 4px 2px 12px -2px rgba(0, 0, 0, 0.16);
}

.hero-illo-container .two {
  position: absolute;
  border-radius: 18px;
  box-shadow: 4px 2px 12px -2px rgba(0, 0, 0, 0.16);
  left: -100px;
}

.hero-illo-container .three {
  border-radius: 18px;
  box-shadow: 4px 2px 12px -2px rgba(0, 0, 0, 0.16);
  position: absolute;
  right: -100px;
}

.feature-container {
  padding: 120px 8px;
}

.mobile-menu-icon {
  display: none;
}

@media (max-width: 900px) {
  .mobile-menu-icon {
    display: block;
    cursor: pointer;
  }

  .hero {
    padding: 64px 0px;
  }

  .hero h1 {
    font-size: 32px;
  }

  .hero h2 {
    font-size: 18px;
  }

  .feature-container {
    padding: 64px 16px;
  }

  .hero-illo-container {
    padding: 0px 16px;
  }

  .hero-illo-container .two {
    display: none;
  }
  .hero-illo-container .three {
    display: none;
  }

  .nav-links {
    display: none;
  }
}

.feature-container.white {
  background-color: #fff;
}

.feature-container.green {
  background-color: #3b7b6e;
  background-size: contain;
  background-position: center;
  background-image: linear-gradient(
      rgba(246, 246, 247, 0),
      rgba(246, 246, 247, 0)
    ),
    url('https://storage.googleapis.com/happy-team-de21b.appspot.com/hero-bg-transparent.png');
}

.feature-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 120px;
  grid-row-gap: 24px;
}

.feature-image-container {
  position: relative;
  padding-top: 120%;
}

.feature-image {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  border-radius: 18px;
  box-shadow: 4px 2px 12px -2px rgba(0, 0, 0, 0.16);
}

.feature-grid label {
  font-family: var(--font-primary);
  font-weight: 600;
  font-size: 16px;
  color: #3b7b6e;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.feature-grid label:before {
  position: absolute;
  content: ' ';
  height: 2px;
  width: 30px;
  background-color: #3b7b6e;
  left: -30px;
}

.feature-grid label span {
  padding-left: 12px;
}

@media (max-width: 900px) {
  .feature-grid label span {
    padding-left: 56px;
  }

  .feature-grid label:before {
    left: 16px;
  }
}

.feature-grid-content {
  padding-left: 12px;
}

.feature-grid-content h2 {
  font-size: 48px;
  font-weight: 600;
  color: #222;
  font-family: var(--font-primary);
  line-height: 1.25;
  margin-bottom: 24px;
}

.feature-grid-content h3 {
  font-size: 18px;
  font-weight: 600;
  color: #222;
  font-family: var(--font-primary);
  line-height: 1.25;
  margin-bottom: 12px;
}

.feature-grid-content h4 {
  font-size: 14px;
  font-weight: 600;
  color: #222;
  font-family: var(--font-primary);
  line-height: 1.25;
}

@media (max-width: 900px) {
  .feature-grid-content h2 {
    font-size: 28px;
  }

  .feature-grid {
    grid-template-columns: 1fr;
  }

  .feature-image-container {
    display: none;
  }
}

.feature-grid-content .Polaris-Heading {
  font-size: 16px;
  margin-bottom: 0px;
}

.feature-grid-content .Polaris-Icon--colorPrimary svg {
  fill: #fff;
}

.feature-grid-content p {
  font-size: 16px;
  color: rgb(100, 98, 98);
  font-weight: 500;
  line-height: 1.5;
}

.feature-icon {
  height: 40px;
  width: 40px;
  border-radius: 40px;
  background-color: #3b7b6e;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feature-checklist {
  margin-top: 48px;
  margin-bottom: 48px;
}

.feature-item {
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-column-gap: 6px;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 24px;
}

.feature-item p {
  font-size: 14px;
}

.cta-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 64px;
}

.demo-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.demo-container img {
  width: auto;
  height: auto;
  max-height: 200px;
  height: 200px;
  border-radius: 12px;
  margin-left: auto;
  margin-right: auto;
}

.cta-container h4 {
  font-size: 48px;
  font-weight: 600;
  color: #fff;
  font-family: var(--font-primary);
  line-height: 1.25;
  margin-bottom: 20px;
}

.demo-title h4 {
  font-size: 32px;
  font-weight: 600;
  color: #fff;
  font-family: var(--font-primary);
  line-height: 1.25;
  margin-bottom: 48px;
}

.pricing-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 24px;
}

@media (max-width: 900px) {
  .pricing-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }

  .cta-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 64px;
    padding: 32px 8px;
  }

  .cta-container h4 {
    font-size: 32px;
  }

  .demo-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 64px;
    padding: 32px 8px;
  }

  .demo-title h4 {
    font-size: 32px;
  }
}

.pricing-container .Polaris-Heading {
  color: #3b7b6e;
  font-size: 28px;
}

.pricing-container h2 {
  margin-bottom: 10px;
}

.pricing-container ul {
  padding-left: 16px;
}

.pricing-container li {
  line-height: 2;
}

h2 small {
  font-size: 16px;
}

.premium-feature .Polaris-Icon {
  height: 1rem;
  width: 1.5rem;
}

.premium-feature .Polaris-Icon path {
  fill: #3b7b6e;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer img {
  height: 38px;
  width: auto;
}

.mobile-menu {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #fff;
  z-index: 2;
}

.mobile-menu-container {
  padding: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.mobile-menu-container a {
  font-size: 24px;
  color: #000;
  text-decoration: none;
  font-weight: 600;
}

.mobile-menu-container .link {
  line-height: 2;
  width: 100%;
  text-align: center;
  padding: 8px 0px;
  border-bottom: 1px solid #f5f5f5;
}

.mobile-menu-container button {
  margin-top: 24px;
}

.close {
  position: absolute;
  right: 16px;
  top: 16px;
}

.close svg {
  height: 20px;
  cursor: pointer;
}

.field-row {
  display: grid;
  grid-template-columns: 1fr 120px;
  grid-column-gap: 16px;
}

.company-logo {
  height: auto;
  width: 100px;
}

.company-logo-wrapper {
  background-color: #f5f5f5;
  width: fit-content;
  padding: 12px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.branding-preview-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 24px;
}

.branding-mock-email {
  border: 1px dashed #f5f5f5;
  padding: 8px;
}

.mock-email {
  background-color: #f5f5f5;

  border-radius: 4px;
  padding: 24px 8px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mock-email-body {
  padding: 8px 12px;
  background-color: #fff;
  width: 85%;
  border-radius: 4px;
  box-shadow: 4px 2px 12px -2px rgba(0, 0, 0, 0.08);
}

.mock-email-logo-banner {
  background-color: #fff;
  padding: 4px 4px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 6px;
}

.mock-email-body-wrap {
  max-width: 75%;
  margin-left: auto;
  margin-right: auto;
  padding: 24px 0px;
}

.mock-email-body-wrap span {
  line-height: 1.2;
  font-size: 5px;
  padding: 12px 0px;
  display: block;
}

.mock-email-logo-banner img {
  width: auto;
  height: 22px;
}

[data-custom-class='body'],
[data-custom-class='body'] * {
  background: transparent !important;
}
[data-custom-class='title'],
[data-custom-class='title'] * {
  font-family: Arial !important;
  font-size: 26px !important;
  color: #000000 !important;
}
[data-custom-class='subtitle'],
[data-custom-class='subtitle'] * {
  font-family: Arial !important;
  color: #595959 !important;
  font-size: 14px !important;
}
[data-custom-class='heading_1'],
[data-custom-class='heading_1'] * {
  font-family: Arial !important;
  font-size: 19px !important;
  color: #000000 !important;
}
[data-custom-class='heading_2'],
[data-custom-class='heading_2'] * {
  font-family: Arial !important;
  font-size: 17px !important;
  color: #000000 !important;
}
[data-custom-class='body_text'],
[data-custom-class='body_text'] * {
  color: #595959 !important;
  font-size: 14px !important;
  font-family: Arial !important;
}
[data-custom-class='link'],
[data-custom-class='link'] * {
  color: #3030f1 !important;
  font-size: 14px !important;
  font-family: Arial !important;
  word-break: break-word !important;
}

ul {
  list-style-type: square;
}
ul > li > ul {
  list-style-type: circle;
}
ul > li > ul > li > ul {
  list-style-type: square;
}
ol li {
  font-family: Arial;
}

.survey-page {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.survey-page .Polaris-Stack__Item {
  margin-top: 6px;
}

.survey-page .Polaris-Choice__Label {
  font-size: 16px;
}

.Polaris-Page__Content {
  padding: 0px 8px;
}

.Polaris-Card {
  border-radius: 8px;
}

.ant-progress-text {
  font-size: 42px !important;
}

.mood-score {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 12px;
  color: #252525;
  flex-direction: column;
}

.Polaris-TopBar-UserMenu__Name,
.Polaris-TopBar-UserMenu__Detail {
  margin-bottom: 0px;
}

.promo {
  background-color: #222;
  position: sticky;
  top: 0;
  padding: 12px 6px;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 700;
  cursor: pointer;
  transition: 0.2s ease;
}

.promo:hover {
  background-color: #2e2e2f;
}

.button-row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-row a {
  margin-left: 4px;
  margin-right: 4px;
}

@media (max-width: 720px) {
  .button-row {
    flex-direction: column;
  }

  .button-row a {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 6px;
  }
}

.checkout {
  font-family: var(--font-primary);
}

.checkout h1 {
  font-family: var(--font-primary);
  font-size: 38px;
  font-weight: 600;
  margin-bottom: 0px;
}


.promo-wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
}